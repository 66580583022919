/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

#root {
    font-family: 'Roboto', sans-serif;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

body, html {
    overflow-y:  hidden;
}
