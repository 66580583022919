.row-cols-2 div {
    border: 1px solid black
}
.spinner {
    width: 100px;
    height: 100px;

    margin: 100px auto;
    -webkit-animation: rotate-div 1.2s infinite ease-in-out;
    animation: rotate-div 1.2s infinite ease-in-out;
}

@keyframes rotate-div {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    } 50% {
          transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
          -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      } 100% {
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);

        }
}
