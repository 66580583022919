html, body, .container {

    height: 100%;

}


div.logo-wrapper img {
    width: auto;
    max-height: 100%;
    max-width: 100%;

}
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;


}

.logo {
    animation: zoom-in 5s; /* El logo hará zoom durante 3 segundos */
}

@keyframes zoom-in {
    0% {
        transform: scale(0.6);
        opacity: 0
    }
    100% {
        transform: scale(1);
        opacity: 1
    }
}

/* Estos estilos son para la animación de fade-out del splash screen */
.splash-enter {
    opacity: 1;
}

.splash-enter-active {
    opacity: 0;
    transition: opacity 500ms;
}

.splash-exit {
    opacity: 0;
}
